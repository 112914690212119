<template>
  <div class="list-item row" v-if="condition">
    <div>
      <p class="text">{{ label }}</p>
    </div>
    <div>
      <p class="text">{{ value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    condition: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/List";
</style>
