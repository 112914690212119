<template>
  <div class="list-container mb-2">
    <ListItem
      v-for="(item, index) in data"
      :key="index"
      :label="item.label"
      :value="item.value"
      :condition="item.condition"
    />
  </div>
</template>

<script>
import ListItem from "@/components/List/ListItem.vue";

export default {
  name: "List",
  components: {
    ListItem,
  },
  props: {
    data: { type: Array, default: () => {} },
  },
};
</script>

<style lang="scss" scoped>
@import "/List";
</style>
